import { eventQBus } from '@otto-ec/global-resources/event-q-bus'

export interface AppsBridge {
  runningInApp(): boolean

  cmp: {
    openPreferenceCenter(): void
    optOutAll(): void
    acceptConsents(): void
    optOutGeneralVendor(vendorId: string): void
    optOutIabVendor(vendorId: string): void
    optOutGoogleVendor(vendorId: string): void
  }
}

declare global {
  interface Window {
    o_apps: AppsBridge
  }
}

export enum APP_RESURFACE_BANNER_EVENTS {
  ResurfaceBanner = `apps.cookie-banner.resurface`,
}

export class AppsAdapter {
  runningInApp(): boolean {
    return this.#apps?.runningInApp() ?? false
  }

  openPreferenceCenter(): void {
    this.#apps?.cmp.openPreferenceCenter()
  }

  resurfaceBanner(): void {
    return eventQBus.emit(APP_RESURFACE_BANNER_EVENTS.ResurfaceBanner)
  }

  optOutAll(): void {
    this.#apps?.cmp.optOutAll()
  }
  acceptConsents(): void {
    this.#apps?.cmp.acceptConsents()
  }
  optOutGeneralVendor(vendorId: string): void {
    this.#apps?.cmp.optOutGeneralVendor(vendorId)
  }
  optOutIabVendor(vendorId: string): void {
    this.#apps?.cmp.optOutIabVendor(vendorId)
  }
  optOutGoogleVendor(vendorId: string): void {
    this.#apps?.cmp.optOutGoogleVendor(vendorId)
  }

  get #apps(): AppsBridge | undefined {
    return window.o_apps ?? undefined
  }
}
