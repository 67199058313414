import { LoggingService } from './logging-service'

export const loggingService = new LoggingService()

export interface Logger {
  logMessage(...messages: unknown[]): void

  logMessageWithId(id: string, ...messages: unknown[]): void

  logError(...error: unknown[]): void
}
