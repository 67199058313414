/**
 *
 *
 */
import { sendLog } from '@otto-ec/global-resources/debug'
import { Logger } from './logger'

export class LoggingService implements Logger {
  constructor(private readonly random: () => number = Math.random) {}

  private static readonly NAMESPACE_CMP = 'eprivacy_cmp'

  /**
 *
 *
 *
 *
 *
 */
  logMessageWithId(id: string, ...messages: unknown[]) {
    this.logMessages(id, messages)
  }

  /**
 *
 *
 *
 */
  logMessage(...messages: unknown[]) {
    this.logMessages(null, messages)
  }

  logError(...errors: unknown[]) {
    const message = this.joinToString(errors)

    if (!message || message.length === 0) {
      return
    }
    const data = {
      message: this.trimMessage(message),
      ...(errors.length === 1 && errors[0] instanceof Error
        ? { stack: this.trimMessage(errors[0].stack, 768) }
        : {}),
      ...(errors.length === 1 && errors[0] instanceof Error
        ? { errorName: this.trimMessage(errors[0].name, 512) }
        : {}),
    }
    void sendLog(LoggingService.NAMESPACE_CMP, data)
  }

  private joinToString(data: unknown[]) {
    return data
      .map((arg) => {
        if (typeof arg === 'string') {
          return arg
        } else if (arg instanceof Error) {
          return arg.message
        } else {
          try {
            return JSON.stringify(arg)
            /*                                                         */
          } catch (e) {
            return String(arg)
          }
        }
      })
      .join(' ')
  }

  sample(message: string, sampleRate: number) {
    if (this.random() < sampleRate) {
      this.logMessage(message)
    }
  }

  private trimMessage(message?: string, numChars: number = 512) {
    return message?.substring(0, numChars).trim()
  }

  private logMessages(id: string | null, messages: unknown[]) {
    const joinedMessage = this.joinToString(messages)
    const trimmedMessage = this.trimMessage(joinedMessage)
    if (!trimmedMessage || trimmedMessage.length === 0) {
      return
    }
    const data = {
      message: trimmedMessage,
      ...(id ? { id: this.trimMessage(id, 256) } : {}),
    }
    void sendLog(LoggingService.NAMESPACE_CMP, data)
  }
}
